import { useAtom } from "jotai";
import React from "react";
import { sceneAtom, videoAtom } from "./atoms";
import video_urls from "./content/video_urls.json";

const throttle = (fn, delay) => {
  // Capture the current time
  let time = Date.now();

  // Here's our logic
  return (...args) => {
    if (time + delay - Date.now() <= 0) {
      // Run the function we've passed to our throttler,
      // and reset the `time` variable (so we can check again).
      fn(...args);
      time = Date.now();
    }
  };
};

export const scene_video_map = {
  intro: {
    src: video_urls.intro,
    instant: true,
    props: {},
  },
  home: {
    src: video_urls.home,
    instant: false,
    props: {},
  },
  projects: {
    src: video_urls.projects,
    instant: false,
    props: {},
  },
  about: {
    src: video_urls.about,
    instant: false,
    props: {},
  },
  people: {
    src: video_urls.people,
    instant: false,
    props: {},
  },
  contact: {
    src: video_urls.contact,
    instant: false,
    props: {},
  },
};

const sceneOrder = ["home", "projects", "about", "people", "contact"];
export const sceneNames = {
  home: "Home",
  projects: "Projecten",
  about: "De Studio",
  people: "De Saus",
  contact: "Contact",
};

export const Overlays = () => {
  const [scene, setScene] = useAtom(sceneAtom);
  const [video, setVideo] = useAtom(videoAtom);

  const changeScene = (newScene) => {
    setScene(newScene);
    setVideo(scene_video_map[newScene]);
  };

  // const nextScene = () => {
  //   const index = sceneOrder.indexOf(scene);
  //   if (index < sceneOrder.length - 1) {
  //     changeScene(sceneOrder[index + 1]);
  //   }
  // };

  // const prevScene = () => {
  //   const index = sceneOrder.indexOf(scene);
  //   if (index > 0) {
  //     changeScene(sceneOrder[index - 1]);
  //   }
  // };

  // React.useEffect(() => {
  //   const handleScroll = (e) => {
  //     if (e.deltaY > 0) {
  //       nextScene();
  //     } else {
  //       prevScene();
  //     }
  //   };

  //   const throttledHandleScroll = throttle(handleScroll, 100);

  //   window.addEventListener("scroll", throttledHandleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", throttledHandleScroll);
  //   };
  // }, [scene]);

  return (
    <>
      {/* <div className="prev-container">
        {sceneOrder.indexOf(scene) > 0 && scene !== "info" && (
          <button onClick={prevScene} className="nav-button">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="18 15 12 9 6 15"></polyline>
              </svg>
            </div>
          </button>
        )}
      </div> */}
      {scene !== "intro" &&  (
        <div className="menu-list">
          {sceneOrder.map((s) => (
            <button
              key={s}
              onClick={() => changeScene(s)}
              className={s === scene ? "active" : ""}
            >
              {sceneNames[s]}
            </button>
          ))}
        </div>
      )}
      {/* <div className="next-container">
        {scene !== "intro" && scene !== "info" &&
          sceneOrder.indexOf(scene) < sceneOrder.length - 1 && (
            <button onClick={nextScene} className="nav-button">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </div>
            </button>
          )}
      </div> */}
      <div className="info-button">
        <button onClick={() => setScene("info")} aria-label="Info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </button>
      </div>
    </>
  );
};
