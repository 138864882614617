{
  "scanlines": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673114300/saus/scanlines_gzsdct.mp4",
  "static": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673121254/saus/static_arxtyu.mp4",
  "intro": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673114449/saus/default_migluy.mp4",
  "home": "https://res.cloudinary.com/dnbztsrik/video/upload/v1723121449/reel_gtnvbn.mp4",
  "projects": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673119889/saus/placeholder_dqdlqo.mp4",
  "about": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673119889/saus/placeholder_dqdlqo.mp4",
  "people": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113992/saus/people_yp42gk.mp4",
  "contact": "https://res.cloudinary.com/dnbztsrik/video/upload/v1675435050/saus/contact_t9xu23.mp4"
}
