import * as THREE from "three";
import React, { Suspense } from "react";
import {
  Float,
  Html,
  MeshReflectorMaterial,
  useCursor,
  useGLTF,
} from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Screen } from "./Screen";
import { useAtom } from "jotai";
import { hitsAtom, sceneAtom, videoAtom } from "./atoms";
import { useSpring, animated } from "@react-spring/three";
import { scene_video_map } from "./Overlays";
import { useFrame } from "@react-three/fiber";
import useSound from "use-sound";
import { ProjectsSection } from "./mainSections/projects";
import { AboutSection } from "./mainSections/about";
import { InfoSection } from "./mainSections/info";
import { StatsSection } from "./mainSections/stats";
import { Hand } from "./Hand";
import { IntroSection } from "./mainSections/intro";
import { ContactSection } from "./mainSections/contact";
import { IntroSection2 } from "./mainSections/intro-2";
import { PeopleSikkoSection } from "./mainSections/PeopleSikko";
import { PeopleEvanneSection } from "./mainSections/PeopleEvanne";

type GLTFResult = GLTF & {
  nodes: {
    Circle001_larger_baseplate_0: THREE.Mesh;
    Glas: THREE.Mesh;
    TV: THREE.Mesh;
    Canvas: THREE.Mesh;
    Cube: THREE.Mesh;
  };
  materials: {
    larger_baseplate: THREE.MeshStandardMaterial;
    ["Shell.004"]: THREE.MeshPhysicalMaterial;
    ["Shell.002"]: THREE.MeshStandardMaterial;
    ["Shell.003"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
  };
};

type SceneConfig = {
  position: [number, number, number];
  rotation: [number, number, number];
  floatIntensity: number;
};

const sceneConfig = {
  intro: {
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    floatIntensity: 0.2,
  },
  home: {
    position: [-0.5, 0, 3],
    rotation: [0, 0, 0],
    floatIntensity: 1,
  },
  projects: {
    position: [-3, 0, 1],
    rotation: [0, Math.PI / 4, 0.1],
    floatIntensity: 1,
  },
  projectsVertical: {
    position: [0, 0.5, -4],
    rotation: [0, 0, -Math.PI / 2],
    floatIntensity: 1,
  },
  about: {
    position: [3, 0, 3],
    rotation: [0, -Math.PI / 3, 0.2],
    floatIntensity: 1,
  },
  people: {
    position: [0, 0.5, -4],
    rotation: [0, 0, -Math.PI / 2],
    floatIntensity: 1,
  },
  contact: {
    position: [1.5, 0, 2],
    rotation: [0, 0, 0],
    floatIntensity: 1,
  },
  info: {
    position: [0.5, 0, 0],
    rotation: [0, Math.PI, 0],
    floatIntensity: 1,
  },
};

export function Scene(props: JSX.IntrinsicElements["group"]) {
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [play] = useSound("/hit.wav", { playbackRate });

  const [scene, setScene] = useAtom(sceneAtom);
  const [video, setVideo] = useAtom(videoAtom);
  const [allTimeHits, setAllTimeHits] = useAtom(hitsAtom);

  const handMeshRef = React.useRef<THREE.Group>();
  const sceneRef = React.useRef<THREE.Group>();
  const handAnimationActive = React.useRef(false);
  const handAnimationProgress = React.useRef(0);

  const [hits, setHits] = React.useState(0);

  const [hovered, setHovered] = React.useState(false);
  useCursor(hovered);

  const { nodes, materials } = useGLTF(
    "/models/tv.glb"
  ) as unknown as GLTFResult;

  const currentSceneConfig = useSpring<SceneConfig>(sceneConfig[scene]);

  const handleClick = () => {
    if (!localStorage.getItem("firstHit")) {
      localStorage.setItem(
        "firstHit",
        new Date().toLocaleDateString("nl-NL", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
    localStorage.setItem("hits", (allTimeHits + 1).toString());
    setAllTimeHits(allTimeHits + 1);

    setPlaybackRate(1 + 0.5 * (Math.random() - 0.5));
    handAnimationProgress.current = 0;
    sceneRef.current?.scale.set(1, 1, 1);
    sceneRef.current?.rotation.set(0, 0, 0);
    handMeshRef.current?.scale.set(1, 1, 1);
    handAnimationActive.current = true;
    setTimeout(() => {
      play();
    }, 100);
    if (hits === 1) {
      setTimeout(() => {
        setVideo(scene_video_map["intro"]);
      }, 300);
      setTimeout(() => {
        setScene("home");
        setVideo({ ...scene_video_map["home"], instant: true });
      }, 3500);
    }
    setHits(hits + 1);
  };

  useFrame(() => {
    if (handAnimationActive.current) {
      // const progress = 0.5 - (handAnimationProgress.current % 0.5);
      let progress = 0;
      if (handAnimationProgress.current < 0.5) {
        progress = 0.5 - (handAnimationProgress.current % 0.5);
      } else if (handAnimationProgress.current < 0.7) {
        progress = 0;
        sceneRef.current?.scale.set(1.01, 0.99, 0.99);
        sceneRef.current?.rotation.set(0, 0, Math.random() * 0.02);
        handMeshRef.current?.scale.set(1.1, 1.1, 1.1);
      } else {
        progress = Math.sin(((handAnimationProgress.current - 0.2) % 0.5) * 2);
        sceneRef.current?.scale.set(1, 1, 1);
        sceneRef.current?.rotation.set(0, 0, 0);
        handMeshRef.current?.scale.set(1, 1, 1);
      }

      handMeshRef.current.position.y = progress * 30 + 1.5;
      handMeshRef.current.rotation.x = progress * 2;
      handAnimationProgress.current += 0.05;
      if (handAnimationProgress.current > 1) {
        handAnimationActive.current = false;
        handAnimationProgress.current = 0;
      }
    }
  });

  return (
    <group {...props} dispose={null} ref={sceneRef}>
      <animated.group
        position={currentSceneConfig.position}
        rotation={currentSceneConfig.rotation}
      >
        <Float
          speed={1} // Animation speed, defaults to 1
          rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
          floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
          floatingRange={[-0.1, 0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
          <group ref={handMeshRef} position={[1, 30, 1]} rotation={[0, 0, 0]}>
            <Hand />
          </group>

          <Suspense
            fallback={
              <mesh
                geometry={nodes.Canvas.geometry}
                material={materials["Shell.003"]}
                position={[0.45, -0.13, 0]}
                rotation={[-Math.PI / 2, 0, 0]}
              >
                <meshStandardMaterial
                  color="#2a2a2a"
                  roughness={0}
                  metalness={1}
                />
              </mesh>
            }
          >
            <Screen />
          </Suspense>
          <mesh
            geometry={nodes.TV.geometry}
            material={materials["Shell.002"]}
            position={[0.45, -0.13, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            onPointerDown={handleClick}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
          />
          <mesh
            geometry={nodes.Circle001_larger_baseplate_0.geometry}
            material={materials.larger_baseplate}
            position={[2.22, -0.26, 1.52]}
            scale={0.22}
          />
        </Float>
        <mesh position={[0.7, -6.76, 0]}>
          <boxGeometry args={[8, 10, 8]} />
          <meshStandardMaterial color="#797979" metalness={0.8} roughness={1} />
        </mesh>
        <mesh rotation-x={Math.PI * -0.5} position={[0.7, -1.75, 0]}>
          <planeGeometry args={[8, 8]} />
          <MeshReflectorMaterial
            blur={[100, 20]}
            resolution={512}
            mixBlur={1}
            mixStrength={100}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#494848"
            metalness={0.8}
            mirror={0}
          />
        </mesh>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, 0, 0]}
          position={[1, 2, 2]}
        >
          <IntroSection active={scene === "intro" && hits === 0} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, 0, 0]}
          position={[1.7, 1.8, 2]}
        >
          <IntroSection2 active={scene === "intro" && hits === 1} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, Math.PI * -0.4, 0]}
          position={[4, 2.5, 4]}
        >
          <ProjectsSection active={scene === "projects"} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, Math.PI * 0.5, 0]}
          position={[-4, 0.9, 3]}
        >
          <AboutSection active={scene === "about"} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, 0, Math.PI / 2]}
          position={[0, -4, 4]}
        >
          <PeopleEvanneSection active={scene === "people"} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, 0, Math.PI / 2]}
          position={[0, 3.8, 4]}
        >
          <PeopleSikkoSection active={scene === "people"} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, 0, 0]}
          position={[-3.3, 0, 1]}
        >
          <ContactSection active={scene === "contact"} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, Math.PI, 0]}
          position={[0.5, 2, -2]}
        >
          <StatsSection active={scene === "info"} />
        </Html>
        <Html
          distanceFactor={4}
          transform
          zIndexRange={[998, 0]}
          rotation={[0, Math.PI, 0]}
          position={[0.5, -1.1, -2]}
        >
          <InfoSection active={scene === "info"} />
        </Html>
      </animated.group>
    </group>
  );
}

if (window.innerWidth >= 1024) {
  useGLTF.preload("/models/tv.glb");
}
